import geocodingService from '@mapbox/mapbox-sdk/services/geocoding';
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

import { GqlState } from 'types/graphql';
import { MAPBOX_TOKEN } from 'shared/constants';
import { isLowerCase, removeAllSpecialCharactersWithoutDash, removeDiacritics } from 'shared/helpers/utils';
import { buildLocationObjectFromMapboxFeature, LocationObject } from 'shared/helpers/mapbox';
import { DispensariesPage } from 'src/dispensaries';
import { MainLayout } from 'components/layouts/main-layout';
import CITIES_QUERY from 'src/cities/cities-query.gql';
import { initializeApollo } from 'utils/apollo/client';
import { countryToStateMap } from 'shared/constants/cities-geography';
import { removeUnsupportedCities } from 'src/cities/utils';

type GetStaticPropsReturn =
  | {
      notFound: boolean;
      revalidate?: number;
    }
  | {
      props: {
        location: Partial<LocationObject>;
        doNotRedirectOnMissingAddress: boolean;
        initialApolloState: NormalizedCacheObject;
      };
      revalidate: number;
      redirect?: undefined;
    }
  | {
      redirect: {
        destination: string;
        permanent: boolean;
      };
      props?: undefined;
    };

type GetStaticPathsReturn = {
  paths: Array<string>;
  fallback: boolean | string;
};

type GetAllCitiesReturn = {
  statesWithDispensaries?: GqlState[];
} | null;

const getLowerCaseUrl = (countryCode: string, stateCodeAndCity: string): string =>
  `/${countryCode.toLowerCase()}/dispensaries/${stateCodeAndCity.toLowerCase()}`;

const getFormattedCityNameToCompare = (cityName: string): string =>
  removeAllSpecialCharactersWithoutDash(removeDiacritics(cityName.toLowerCase()).split(' ').join('-'));

const getCityWithDispensary = (data: GetAllCitiesReturn, stateCode: string, cityWithDashes: string): string | null => {
  const state = data?.statesWithDispensaries?.find((s: GqlState) => s.name === stateCode);
  const city = state?.cities.filter(Boolean).find((c: string) => getFormattedCityNameToCompare(c) === cityWithDashes);

  return city ?? null;
};

const getAllCities = async (apolloClient: ApolloClient<NormalizedCacheObject>): Promise<GetAllCitiesReturn> => {
  const { data } = await apolloClient.query({
    query: CITIES_QUERY,
  });

  return data;
};

const mapboxGeocodingClient = geocodingService({ accessToken: MAPBOX_TOKEN });

const getMapboxQuery = (countryCode: string, stateCode: string, city: string): string =>
  `${city}, ${(countryToStateMap[countryCode.toUpperCase()][stateCode] || stateCode) as string}`;

const getLocationFromMapbox = async (
  countryCode: string,
  stateCode: string,
  city: string
): Promise<Partial<LocationObject>> => {
  const response = await mapboxGeocodingClient
    .forwardGeocode({
      query: getMapboxQuery(countryCode, stateCode, city),
      limit: 1,
      types: ['address', 'country', 'district', 'locality', 'neighborhood', 'place', 'postcode', 'region'],
      countries: [countryCode.toUpperCase()],
    })
    .send();

  if (!response.body?.features?.[0] || response.statusCode !== 200) {
    console.error('Problem attempting to get Mapbox location suggestions: ', response);
    return {};
  }

  return buildLocationObjectFromMapboxFeature(response.body.features[0]);
};

/*
 * Utilizes statesWithDispensaries query to get a list of all cities where we have dispensaries,
 * organized by state. URL [stateCodeAndCityName] param must be in format st-city-name, where st
 * matches an ISO state code exactly and city name matches a city in that state exactly. It's
 * important for SEO that we do not deviate from this route structure. We then use Mapbox to get
 * the location information for that city.
 */
export async function getStaticProps({ params }): Promise<GetStaticPropsReturn> {
  const { countryCode, stateCodeAndCityName: stateCodeAndCityFromRoute } = params;
  const stateCodeAndCityName = stateCodeAndCityFromRoute.split('-');

  // In the future when we support state pages, this will go away
  // For now, 404
  if (stateCodeAndCityName.length < 2) {
    return {
      notFound: true,
    };
  }

  // If [countryCode] or [stateCodeAndCityName] are not all lowercase, automatically redirect
  // to the all-lower-case version.
  if (!(isLowerCase(countryCode) && isLowerCase(stateCodeAndCityFromRoute))) {
    return {
      redirect: {
        destination: getLowerCaseUrl(countryCode, stateCodeAndCityFromRoute),
        permanent: true,
      },
    };
  }

  const stateCode = stateCodeAndCityName[0].toUpperCase();
  const cityWithDashes = stateCodeAndCityName.splice(1).join('-');

  // Query for all cities with dispensaries by country/state
  const apolloClient = initializeApollo();
  const data = await getAllCities(apolloClient);

  // Remove cities for which a city page cannot be created
  removeUnsupportedCities(data);

  // Try to find a matching city with a dispensary serviced by Dutchie
  const cityWithDispensary = getCityWithDispensary(data, stateCode, cityWithDashes);

  // If there are no dispensaries with city matching the queried city, 404
  if (!cityWithDispensary) {
    return {
      notFound: true,
      revalidate: 60,
    };
  }

  const location = await getLocationFromMapbox(countryCode, stateCode, cityWithDispensary);

  return {
    props: {
      location,
      doNotRedirectOnMissingAddress: true,
      // Not doing anything for now, but once DispensariesPage can actually be ISG'd,
      // we should add other required queries above and this will become useful
      initialApolloState: apolloClient.cache.extract(),
    },
    revalidate: 60 * 60, // One hour
  };
}

export function getStaticPaths(): GetStaticPathsReturn {
  return {
    paths: [],
    // If path hasn't been built yet, we want to treat the page as SSR
    fallback: `blocking`,
  };
}

// eslint-disable-next-line lodash/prefer-lodash-method
export default Object.assign(DispensariesPage, { layout: MainLayout });
